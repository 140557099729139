<div class="row" *ngIf = "!router.url.startsWith('/profile/')">
    <div class="col-3">
        <div class="card fill py-3 px-2" style="height: 270px;">
            <p class="text-center">
                <strong class="font-17">Athens</strong>
                <br> <span class="font-13">Mon, 12:30 PM, Mostly Sunny</span>
            </p>
            <p class="font-50 text-center my-4">
                <strong>23<sup>o</sup>C</strong>
            </p>
            <p class="text-center mt-3">
                <span class="font-11"> 
                    <img src="assets/images/drop.png" class="mb-1 mr-1"> 3% Precipitation 
                    <img src="assets/images/leaf.png" class="ml-3 mr-1"> 21 km/h Winds
                </span>
            </p>
        </div>
    </div>

    <div class="col-6">
        <div class="card fill pt-4" style="height: 270px;">
            <div class="row my-4">
                <div class="col-6">
                    <p class="text-center">
                        <img src="assets/images/lightning.png" style="margin-bottom:33px;" alt="">
                        <br> <strong class="font-17">Average Daily Consumption</strong>
                        <br> <strong class="font-50">11,3kWh</strong>
                    </p>
                </div>
                <div class="col-6">
                    <ng-template #comparisonContent>
                        <div class="row" style="width: 300px;">
                            <div class="col-4 text-left">
                                <p>
                                    Neighbors<br>
                                    <strong>9,8kWh</strong>
                                </p>
                                <p>
                                    1,15€
                                </p>
                            </div>
                            <div class="col-4 text-left">
                                <p>
                                    You<br>
                                    <strong>11,3kWh</strong>
                                </p>
                                <p>
                                    1,50€
                                </p>
                            </div>
                            <div class="col-4 text-left">
                                <p>
                                    Top 10%<br>
                                    <strong>7,7kWh</strong>
                                </p>
                                <p>
                                    0,90€
                                </p>
                            </div>
                        </div>
                    </ng-template>
                    <img src="assets/images/peer.png" class="float-left pointer" style="margin-top: -23px;" alt="" [ngbTooltip]="comparisonContent " alt="" placement="bottom">
                
                </div>
            </div>
        </div>
    </div>

    <div class="col-3">
        <div class="card fill py-3 px-4" style="height: 270px;">
            <p class="text-center">
                <strong class="font-17">Current Balance</strong>
                <br><span class="font-13">Last bill type: Genius</span>
                <br><span class="font-13">Issued: 25/02/2020</span>
            </p>
            <p class="font-50 text-center mt-0 mb-4 py-2 fill-more">
                <strong>0,00€</strong>
            </p>
            <p class="text-center">
            <span class="font-13">Last payment: 27/02/2020</span>
            </p>
        </div>
    </div>

</div>
