<div class="card box-white shadow mb-5 px-5 pt-5 pb-0">

    <div class="row">
      <div class="col-8">
        <p class="font-20"><strong>How goals work</strong></p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In cursus lectus erat, eget
            scelerisque lorem scelerisque pretium.</p>
      </div>
      <div class="col-4">
        <button type="button" class="btn btn-primary btn-lg px-5 float-right mt-4"> 
            <strong class="font-14">+ Add your first goal </strong>
        </button>
      </div>
    </div>
    <hr>
    <div class="row">
          <!-- =========================== -->
        <div class="col-3 text-center">
            <div class="py-4">
                <img src="assets/images/calendar.png" class="mb-3" alt="">
                <p class="font-13">Create a consumption goal<br>
                    on a specific date period</p>
            </div>
        </div>
        <div class="col-1">
            <div class="py-auto">
                <img src="assets/images/arrow-left.png" class="mt-5" alt="">
            </div>
        </div>
        <!-- =========================== -->
        <div class="col-4 text-center px-0">
            <div class="py-4">
                <img src="assets/images/cycle.png" class="mb-3" alt="">
                <p class="font-13">Visit this page and see<br>
                    the progress of your goal</p>
            </div>
        </div>
        <div class="col-1">
            <div class="py-auto">
                <img src="assets/images/arrow-left.png" class="mt-5" alt="">
            </div>
        </div>
        <!-- =========================== -->
        <div class="col-3 text-center">
            <div class="py-4">
                <img src="assets/images/pie.png" class="mb-3" alt="">
                <p class="font-13">Get personalised tips that will help
                    you reach the goal</p>
            </div>
        </div>

    </div>
    
  
  </div>
