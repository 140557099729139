import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatFormFieldModule} from '@angular/material/form-field';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { GoogleChartsModule } from 'angular-google-charts';

import { HeaderComponent } from './includes/header/header.component';
import { FooterComponent } from './includes/footer/footer.component';
import { PublicHeaderComponent } from './public/header/header.component';
import { PublicFooterComponent } from './public/footer/footer.component';
import { SearchComponent } from './tests/search/search.component';
import { MoviesComponent } from './tests/movies/movies.component';
import { MovieComponent } from './tests/movie/movie.component';
import { CollectionsComponent } from './tests/collections/collections.component';
import { RatingComponent } from './dialogs/rating/rating.component';
import { AddToCollectionComponent } from './dialogs/add-to-collection/add-to-collection.component';
import { EnergyPageComponent } from './tests/energy-page/energy-page.component';
import { QuickInfoComponent } from './quick-info/quick-info.component';
import { NavigateComponent } from './includes/navigate/navigate.component';
import { HomeComponent } from './home/home.component';
import { ConsumptionComponent } from './energy/consumption/consumption.component';
import { SavingsComponent } from './energy/savings/savings.component';
import { DisaggregationComponent } from './energy/disaggregation/disaggregation.component';
import { GoalsComponent } from './energy/goals/goals.component';
import { MeterComparisonComponent } from './energy/meter-comparison/meter-comparison.component';
import { PeakDemandComponent } from './energy/peak-demand/peak-demand.component';
import { TypicalConsumptionComponent } from './energy/typical-consumption/typical-consumption.component';
import { SidebarComponent } from './includes/sidebar/sidebar.component';
import { ReadingsComponent } from './readings/readings.component';
import { TipsComponent } from './tips/tips.component';
import { BillAnalysisComponent } from './bills/bill-analysis/bill-analysis.component';
import { ExplanationComponent } from './bills/explanation/explanation.component';
import { PaymentComponent } from './bills/payment/payment.component';
import { EBillComponent } from './bills/e-bill/e-bill.component';
import { MeterProfileComponent } from './meter-profile/meter-profile.component';
import { LoginComponent } from './public/login/login.component';
import { HelpComponent } from './profile/help/help.component';
import { RegisterComponent } from './public/register/register.component';
import { ResetPasswordComponent } from './public/reset-password/reset-password.component';
import { TermsComponent } from './public/terms/terms.component';
import { AccountComponent } from './profile/account/account.component';
import { SettingsComponent } from './profile/settings/settings.component';
import { PreferencesComponent } from './profile/preferences/preferences.component';
import { SecurityComponent } from './profile/security/security.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PublicHeaderComponent,
    FooterComponent,
    PublicFooterComponent,
    SearchComponent,
    MoviesComponent,
    MovieComponent,
    CollectionsComponent,
    RatingComponent,
    AddToCollectionComponent,
    EnergyPageComponent,
    QuickInfoComponent,
    NavigateComponent,
    HomeComponent,
    ConsumptionComponent,
    SavingsComponent,
    DisaggregationComponent,
    GoalsComponent,
    MeterComparisonComponent,
    PeakDemandComponent,
    TypicalConsumptionComponent,
    SidebarComponent,
    ReadingsComponent,
    TipsComponent,
    BillAnalysisComponent,
    ExplanationComponent,
    PaymentComponent,
    EBillComponent,
    MeterProfileComponent,
    LoginComponent,
    HelpComponent,
    RegisterComponent,
    ResetPasswordComponent,
    TermsComponent,
    AccountComponent,
    SettingsComponent,
    PreferencesComponent,
    SecurityComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatDialogModule,
    MatSelectModule,
    MatTooltipModule,
    GoogleChartsModule,
    MatExpansionModule,
    MatListModule,
    NgbModule,
    MatTabsModule,
    FontAwesomeModule,
    MatCheckboxModule,
    MatRadioModule,
    MatStepperModule
  ],
  entryComponents: [RatingComponent, AddToCollectionComponent],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
