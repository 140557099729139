<p class="lead">Select a collection &#x2b;</p>

<mat-form-field>
    <mat-label>select collection</mat-label>
    <mat-select [(value)]="selected">
        <mat-option>No collection</mat-option>
        <mat-option *ngFor="let collection of collections; let i = index" value="{{i}}">{{collection.title}} &#x2b;</mat-option>
    </mat-select>
</mat-form-field>

<p>You selected: {{selected}}</p>
<div class="row">
  <div class="col-6">
      <p class="text-left">
          <button mat-button (click)="onCancel()">&#x2715; Cancel</button>
      </p>
  </div>
  <div class="col-6">
      <p class="text-right">
          <button mat-button (click)="onSelectCollection()">&#x2713; Confirm</button>
      </p>
  </div>
</div>
