<div class="row row-cols-2 row-cols-md-4">

    <div class="col mb-4">
      <div class="card">
        <div class="card-body">
            <img src="assets/images/demand-response.png" class="mb-3" alt="...">
          <h5 class="card-title">Demand reponse</h5>
          <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
        </div>
      </div>
    </div>
    <div class="col mb-4">
      <div class="card">
        <div class="card-body">
            <img src="assets/images/lighning.png" class="mb-3" alt="...">
          <h5 class="card-title">Energy eficiency</h5>
          <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
        </div>
      </div>
    </div>
    <div class="col mb-4">
      <div class="card">
        <div class="card-body">
            <img src="assets/images/enviroment-variables.png" class="mb-3" alt="...">
            <h5 class="card-title">Enviroment variables</h5>
            <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
        </div>
      </div>
    </div>
    <div class="col mb-4">
      <div class="card">
        <div class="card-body">
            <img src="assets/images/tools.png" class="mb-3" alt="...">
          <h5 class="card-title">Equipment and tools</h5>
          <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
        </div>
      </div>
    </div>
    <div class="col mb-4">
        <div class="card">
          <div class="card-body">
            <img src="assets/images/lighting.png" class="mb-3" alt="...">
            <h5 class="card-title">Lighting</h5>
            <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
          </div>
        </div>
      </div>
      <div class="col mb-4">
        <div class="card">
          <div class="card-body">
            <img src="assets/images/my-consumption.png" class="mb-3" alt="...">
            <h5 class="card-title">My consumption</h5>
            <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
          </div>
        </div>
      </div>
      <div class="col mb-4">
        <div class="card">
          <div class="card-body">
            <img src="assets/images/recycling.png" class="mb-3" alt="...">
            <h5 class="card-title">Recycling</h5>
            <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
          </div>
        </div>
      </div>
      <div class="col mb-4">
        <div class="card">
          <div class="card-body">
            <img src="assets/images/smart-building.png" class="mb-3" alt="...">
            <h5 class="card-title">Smart building</h5>
            <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
          </div>
        </div>
      </div>

  </div>