import { Component, OnInit } from '@angular/core';
import { faArrowCircleUp, faArrowCircleDown} from '@fortawesome/free-solid-svg-icons';

interface Result {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-savings',
  templateUrl: './savings.component.html',
  styleUrls: ['./savings.component.css']
})
export class SavingsComponent implements OnInit {

  faArrowUp = faArrowCircleUp;
  faArrowDown = faArrowCircleDown;
  
  panelOpenState = false;

  results: Result[] = [
    {value: '1', viewValue: '01 November, 2018 - 31 December, 2018'},
    {value: '2', viewValue: '01 January, 2019 - 28 February, 2019'},
    {value: '3', viewValue: '01 March, 2019 - 30 April, 2019 '}
  ];
  
  selectedValue = '1';

  constructor() { }

  ngOnInit(): void {
  }

}
