<div class="card box-white shadow mb-5 px-5 py-5">

    <div class="row">
      <div class="col-12">
        <p class="font-20"><strong>Energy savings from bills</strong></p>
      </div>
    </div>
    
    <div class="row mt-3">
      <div class="col-12">
        <mat-form-field style="width: 350px;">
          <mat-label>See results for</mat-label>
          <mat-select [(ngModel)]="selectedValue">
            <mat-option *ngFor="let result of results" [value]="result.value">
              {{result.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  
    <div class="row mt-3">
      <div class="col-8">
  
      </div>
      <div class="col-4">
        <img src="assets/images/savings1.png" class="img-fluid" alt="">
      </div>
    </div>
  
    <div class="row mt-3">
      <ng-template #savingsContent>
        <div class="text-left" style="width: 250px;">
           <div class="row">
              <div class="col-8">
                 <p class="blue-text">01 Jan '19 to 28 Feb '19</p>
              </div>
              <div class="col-4">
                 <p class="blue-text text-right">59 days</p>
              </div>
           </div>
           <div class="row">
              <div class="col-12">
                 <p class="">Average daily consumption<br>
                    <strong>1.87kWh</strong>
                 </p>
              </div>
           </div>
           <div class="row">
              <div class="col-6">
                 <p class="">Savings form last month<br>
                    <strong class="green-text"><fa-icon [icon]="faArrowDown" class="font-18 dark-font mr-1"></fa-icon> 7%</strong>
                 </p>
              </div>
              <div class="col-6">
                 <p class="">Savings from previous suplier<br>
                    <strong class="green-text"><fa-icon [icon]="faArrowDown" class="font-18 dark-font mr-1"></fa-icon> 33%</strong>
                 </p>
              </div>
           </div>
        </div>
     </ng-template>
      <div class="col-12">
        <img src="assets/images/savings2.png" class="img-fluid pointer" alt="" [ngbTooltip]="savingsContent" placement="left">
      </div>
    </div>
  
  </div>
