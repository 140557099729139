import { Component, OnInit } from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  
  search = new FormControl('', [
    Validators.required, 
    Validators.minLength(3),
    Validators.pattern('[a-zA-Z0-9 ]*')
  ]);

  constructor(private router: Router) {}

  ngOnInit() {
  }

   getErrorMessage() {
    return this.search.hasError('required') ? 'You must enter a value' :
        this.search.hasError('minlength') ? 'You must enter at least 3 characters' :
        this.search.hasError('pattern') ? 'You allowed to enter only alphanumeric characters' :
            '';
  }

  initSearch() {
    //this.router.navigate(['movies/'+this.search.value]);
  }

  onSubmit() {
    console.log(this.search.value);  // { first: '', last: '' }
    console.log(this.search.valid);  // false
    this.router.navigate(['movies/'+this.search.value]);
  }

}
