<div class="row">
    <div class="col-12">
        <p class="text-center mb-5" style="margin-top: -30px;">
            <button mat-button (click)="goBack()">
                <fa-icon [icon]="faGoBack" class="mr-1"></fa-icon> &nbsp; Return to previous page
            </button>
        </p>
    </div>
</div>

<div class="row mt-2">
    <div class="col-8">
        <div class="card box-white shadow mb-5 px-5 py-5">
            <p class="font-20"><strong>FAQ's</strong></p>
            <div class="row">
                <div class="col-5">
                    <p>
                        <mat-form-field appearance="legacy" class="custom-width">
                        <mat-label>Text search</mat-label>
                        <input matInput placeholder="">
                        <mat-hint>Please enter some key words</mat-hint>
                        </mat-form-field>
                    </p>
                </div>
                <div class="col-5">
                    <mat-form-field class="custom-width">
                        <mat-label>Category select</mat-label>
                        <mat-select>
                          <mat-option *ngFor="let result of results" [value]="result.value">
                            {{result.viewValue}}
                          </mat-option>
                        </mat-select>
                        <mat-hint>Please select a search category</mat-hint>
                      </mat-form-field>
                </div>
            </div>

            <div class="row my-3">
                <div class="col-12">
                    <mat-accordion>
                    
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                   How can I view my bill online?
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="dark-text">
                                <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ut condimentum risus, nec viverra lacus. Nulla id nibh tellus. Nam imperdiet nisl id purus maximus molestie. Curabitur vel arcu lectus. Donec nunc odio, dapibus vel nisi eu, pretium aliquam lacus. Proin massa tellus, fringilla eu mauris vel, tempor aliquam odio. Pellentesque nisi nunc, pharetra non varius vitae, mollis ac nunc.
                                </p>
                                <p>
                                Quisque semper ipsum nec interdum feugiat. Praesent at augue ut massa semper hendrerit a vel ante. Nulla quis est ac risus luctus finibus a quis elit. Etiam nec tellus nibh. Suspendisse eget ex ut nunc lacinia imperdiet eu vitae sapien. Donec id sapien sed ligula ullamcorper ullamcorper. Sed semper eros eget arcu euismod, vitae eleifend arcu congue. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris imperdiet justo lorem, vitae feugiat nulla sodales sit amet.
                                </p>
                                <p class="blue-text"> <fa-icon [icon]="faLike" class="font-18 dark-font mr-1"></fa-icon> Was it helpful?</p>
                            </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    How often will I receive a bill?
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="dark-text">
                                <p>
                                Quisque semper ipsum nec interdum feugiat. Praesent at augue ut massa semper hendrerit a vel ante. Nulla quis est ac risus luctus finibus a quis elit. Etiam nec tellus nibh. Suspendisse eget ex ut nunc lacinia imperdiet eu vitae sapien. Donec id sapien sed ligula ullamcorper ullamcorper. Sed semper eros eget arcu euismod, vitae eleifend arcu congue. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris imperdiet justo lorem, vitae feugiat nulla sodales sit amet.
                                </p>
                                <p>
                                Donec elementum, ante vel vehicula sodales, dolor nulla gravida eros, vitae egestas lectus massa in metus. Nam consectetur est eu ultricies vehicula. Sed et elementum orci. Phasellus pretium placerat nulla, eu vestibulum nisi malesuada quis. Mauris a dolor arcu. Duis aliquam, ex in mollis ultricies, neque enim hendrerit risus, et sollicitudin diam leo id erat. Donec vel eros vel tellus pellentesque tincidunt. Sed porttitor quam et eros scelerisque malesuada. Fusce aliquet lorem rhoncus vulputate ullamcorper.
                                </p>
                                <p class="blue-text"> <fa-icon [icon]="faLike" class="font-18 dark-font mr-1"></fa-icon> Was it helpful?</p>
                            </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel [expanded] = 'true'>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Is there someone I can contact if I have any questions?
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="dark-text">
                                <p>
                                Donec elementum, ante vel vehicula sodales, dolor nulla gravida eros, vitae egestas lectus massa in metus. Nam consectetur est eu ultricies vehicula:
                                </p>
                                <ul>
                                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                                    <li>Aenean quis mauris sollicitudin, tincidunt erat et, facilisis nulla.</li>
                                    <li>Mauris quis arcu vitae lectus consectetur pellentesque.</li>
                                    <li>Integer hendrerit tellus sit amet euismod ornare.</li>
                                    </ul>
                                    <p>Donec vel eros vel tellus pellentesque tincidunt. Sed porttitor quam et eros scelerisque malesuada. Fusce aliquet lorem rhoncus vulputate ullamcorper.</p>
                                    <p class="blue-text"> <fa-icon [icon]="faLike" class="font-18 dark-font mr-1"></fa-icon> Was it helpful?</p>
                                </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    What costs are included in the Competitive Charges?
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="dark-text">
                                <p>
                                Quisque semper ipsum nec interdum feugiat. Praesent at augue ut massa semper hendrerit a vel ante. Nulla quis est ac risus luctus finibus a quis elit. Etiam nec tellus nibh. Suspendisse eget ex ut nunc lacinia imperdiet eu vitae sapien. Donec id sapien sed ligula ullamcorper ullamcorper. Sed semper eros eget arcu euismod, vitae eleifend arcu congue. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris imperdiet justo lorem, vitae feugiat nulla sodales sit amet.
                                </p>
                                <p>
                                Donec elementum, ante vel vehicula sodales, dolor nulla gravida eros, vitae egestas lectus massa in metus. Nam consectetur est eu ultricies vehicula. Sed et elementum orci. Phasellus pretium placerat nulla, eu vestibulum nisi malesuada quis. Mauris a dolor arcu. Duis aliquam, ex in mollis ultricies, neque enim hendrerit risus, et sollicitudin diam leo id erat. Donec vel eros vel tellus pellentesque tincidunt. Sed porttitor quam et eros scelerisque malesuada. Fusce aliquet lorem rhoncus vulputate ullamcorper.
                                </p>
                                <p class="blue-text"> <fa-icon [icon]="faLike" class="font-18 dark-font mr-1"></fa-icon> Was it helpful?</p>
                            </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    What are Regulated Charges?
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="dark-text">
                                <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ut condimentum risus, nec viverra lacus. Nulla id nibh tellus. Nam imperdiet nisl id purus maximus molestie. Curabitur vel arcu lectus. Donec nunc odio, dapibus vel nisi eu, pretium aliquam lacus. Proin massa tellus, fringilla eu mauris vel, tempor aliquam odio. Pellentesque nisi nunc, pharetra non varius vitae, mollis ac nunc.
                                </p>
                                <p>
                                Quisque semper ipsum nec interdum feugiat. Praesent at augue ut massa semper hendrerit a vel ante. Nulla quis est ac risus luctus finibus a quis elit. Etiam nec tellus nibh. Suspendisse eget ex ut nunc lacinia imperdiet eu vitae sapien. Donec id sapien sed ligula ullamcorper ullamcorper. Sed semper eros eget arcu euismod, vitae eleifend arcu congue. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris imperdiet justo lorem, vitae feugiat nulla sodales sit amet.
                                </p>
                                <p class="blue-text"> <fa-icon [icon]="faLike" class="font-18 dark-font mr-1"></fa-icon> Was it helpful?</p>
                            </div>
                        </mat-expansion-panel>

                    </mat-accordion>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                  <mat-paginator [length]="100"
                        [pageSize]="10"
                        [pageSizeOptions]="[5, 10, 25, 100]">
                  </mat-paginator>
                </div>
            </div>

        </div>
    </div>
    <div class="col-4">
        <div class="card box-white shadow mb-5 px-4 py-5">
            <p class="font-20">
                <strong>Need more help?</strong> 
              </p>
              <p class="font-13">Send your issue with a message and we ‘ll
                connect you to an expert.</p>
                <form action="">
                 <div class="row">
                     <div class="col-12">
                        <mat-form-field class="custom-width">
                            <mat-label>Select a reason of contact</mat-label>
                            <mat-select>
                                <mat-option *ngFor="let result of results" [value]="result.value">
                                reason {{result.value}}
                                </mat-option>
                            </mat-select>
                            <mat-hint>Please select one of the available options</mat-hint>
                        </mat-form-field>
                     </div>
                 </div>
                   
                 <div class="row mt-3">
                    <div class="col-12">
                       <mat-form-field class="custom-width">
                           <mat-label>Select a subject</mat-label>
                           <mat-select>
                               <mat-option *ngFor="let result of results" [value]="result.value">
                               subject {{result.value}}
                               </mat-option>
                           </mat-select>
                           <mat-hint>Please select one of the available options</mat-hint>
                       </mat-form-field>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-12">
                        <mat-form-field class="custom-width">
                            <mat-label>Your issue</mat-label>
                            <textarea matInput placeholder="" rows="5"></textarea>
                          </mat-form-field>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12">
                       <mat-form-field class="custom-width">
                           <mat-label>Select associated meters</mat-label>
                           <mat-select>
                               <mat-option *ngFor="let result of results" [value]="result.value">
                               meter {{result.value}}
                               </mat-option>
                           </mat-select>
                           <mat-hint>Please select one of the available options</mat-hint>
                       </mat-form-field>
                    </div>
                </div>

                <div class="row mt-5">
                    <div class="col-12">
                        <p>We will contact you via:</p>
                        <mat-radio-group aria-label="Select an option">
                            <mat-radio-button value="1">Phone</mat-radio-button>
                            <mat-radio-button value="2" class="ml-3">Email</mat-radio-button>
                          </mat-radio-group>
                          <p class="font-13">Working hours of the Customer Support
                            Department: <br>
                            Monday to Saturday: 08:00 a.m. – 21:00 p.m.
                            & Sunday: 09:00 a.m. – 19:00 p.m.</p>
                    </div>
                </div>

                  <button class="btn btn-primary mt-4">
                    Submit
                  </button> 
                </form>
        </div>
    </div>
</div>
