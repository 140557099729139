import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Location} from '@angular/common';

@Component({
  selector: 'app-movies',
  templateUrl: './movies.component.html',
  styleUrls: ['./movies.component.css']
})

export class MoviesComponent implements OnInit {

  cur_page = 1;
  search = '';
  results: Array < Object > = [];
  total_results = '';
  htmlToAdd = '';

  constructor(private _location: Location, private route:ActivatedRoute, private httpClient: HttpClient, private router: Router){

  }

  ngOnInit() {
    this.search = this.route.snapshot.params['title'];
    //this._location.replaceState("/movies/"+this.search+"/32");
   this.get_list();
  }
  

  get_list() {
    this.httpClient.get('https://api.themoviedb.org/3/search/movie?api_key=85204a8cc33baf447559fb6d51b18313&page=' + this.cur_page + '&query=' + this.search)
    .subscribe((res: any) => {
        this.results = res.results;
        this.total_results = res.total_results;
        console.log (this.results);
        if (res.total_results == "0") this.htmlToAdd = `<p class="text-center lead">No results found</p>
        <p class="text-center">Please click <em>Search</em> to try again</p>`;
    });
}

navigateMovie(id) {
  this.router.navigate(['movie/'+id]);
  //this._location.replaceState("/movies/"+this.search+"/"+id);
}

getPaginatorData($event) {
  this.cur_page = $event.pageIndex + 1;
  this.get_list();
}

}
