<div class="row">
    <div class="col-6 offset-3 px-0 pt-4">
        <div class="card blue-box mx-auto mb-0">
            <app-public-header></app-public-header>
         </div>
        <div class="card py-4 px-4 mb-5 dark-font">
            <img src="assets/images/logo2.png" class="mx-auto mt-3 mb-5" alt="">
            <p class="font-20"><strong>Register</strong></p>
            <form action="" class="mb-5">
                <div class="row">
                    <div class="col-6">
                        <p>
                            <mat-form-field appearance="legacy" class="custom-width">
                            <mat-label>Supply number</mat-label>
                            <input matInput placeholder="">
                            <mat-hint>Please you energy supply number</mat-hint>
                            </mat-form-field>
                        </p>
                    </div>
                    <div class="col-6">
                        <p>
                            <mat-form-field appearance="legacy" class="custom-width">
                            <mat-label>Tax identification number</mat-label>
                            <input matInput placeholder="">
                            <mat-hint>Please enter your tax identification number</mat-hint>
                            </mat-form-field>
                        </p>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-12">
                        <p>
                            <mat-form-field appearance="legacy" class="custom-width">
                            <mat-label>Email</mat-label>
                            <input matInput placeholder="">
                            <mat-hint>Please enter a valid email</mat-hint>
                            </mat-form-field>
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <p>
                            <mat-form-field appearance="legacy" class="custom-width">
                            <mat-label>Create password</mat-label>
                            <input matInput placeholder="">
                            <mat-hint>Please create your password</mat-hint>
                            </mat-form-field>
                        </p>   
                    </div>
                    <div class="col-6">
                        <p>
                            <mat-form-field appearance="legacy" class="custom-width">
                            <mat-label>Verify password</mat-label>
                            <input matInput placeholder="">
                            <mat-hint>Please verify your password</mat-hint>
                            </mat-form-field>
                        </p>   
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-12 mx-0">
                        <p><mat-checkbox> &nbsp;  I accept the 
                            <a href="#" class="dark-font" routerLink="terms"><u>Terms & Conditions</u></a>
                        </mat-checkbox></p>     
                    </div>
                </div>   
            
                <button class="btn btn-primary mt-3 font-14" routerLink ="home">
                    Submit
                </button> 
            </form>
            <hr>
            <p class="my-0">Already have an account? <a href="#" routerLink="login" ><u><strong>Login</strong></u></a></p>
        </div>
    </div>
</div>

<app-public-footer></app-public-footer>

