import { Component, OnInit, Inject } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-add-to-collection',
  templateUrl: './add-to-collection.component.html',
  styleUrls: ['./add-to-collection.component.css']
})

export class AddToCollectionComponent implements OnInit {
  
  collections = JSON.parse(localStorage.getItem('collections'));
  new_items: any = '';
  movie = '';
  title = '';
  poster = '';
  selected = '';

  constructor(
    private httpClient: HttpClient,
    private router: Router, 
    private activatedRoute: ActivatedRoute,
    public dialogRef: MatDialogRef<AddToCollectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.movie = this.data.movie;
      this.title = this.data.title;
      this.poster = this.data.image
    }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSelectCollection() {
    this.new_items = this.collections[this.selected].items;
    this.new_items.push({'id':this.movie, 'title':this.title,'poster':this.poster})
    this.collections[this.selected].items = this.new_items;
    console.log (this.collections);
    localStorage.setItem('collections', JSON.stringify(this.collections));
    this.dialogRef.close();
  }

}
