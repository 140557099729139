import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Bill Analysis';
  type = 'ColumnChart';
  data = [
     ["2012", 110.38],
     ["2013", 149.99],
     ["2014", 116.29],
     ["2015", 104.90],
     ["2016", 58.26],
     ["2017", 84.82],
     ["2018", 98.49],
     ["2019", 67.35],
  ];
  options = {
    vAxis: {
      textStyle: {
        fontSize: 13
      },
      baseline:{
        color: 'transparent'
      },
      gridlines: {
          color: 'transparent'
      }
    },
    hAxis: {
      textStyle: {
        fontSize: 13
      }
    }
  };
  

  constructor(public _location: Location, public router: Router) {}

  ngOnInit() {}

  isHomeRoute() {
    return this.router.url === '/';
  }
  isHomePage() {
    return this.router.url === '/home';
  }
}
