import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {Location} from '@angular/common';
import { faHome, faQuestionCircle, faBook, faBell, faStore, faIndustry, faBuilding, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { strict } from 'assert';
import { stringify } from 'querystring';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  faUser = faUserCircle;
  faHome = faHome;
  faQuestionCircle = faQuestionCircle;
  faBook = faBook;
  faBell = faBell;
  faOffice = faBuilding;
  faStore = faStore;
  faFactory = faIndustry;

  selectedMeter:String = '43032740033 - My House';
  selectedIcon = faHome;
  
  constructor(public _location: Location, public router: Router) {}

  goBack() {
    this._location.back();
  }

  ngOnInit() {
    console.log (this._location.path());
  }

  selectMeter(icon,meter){
    this.selectedMeter = meter;
    this.selectedIcon = icon; 
  }
}
