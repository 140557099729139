import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {Location} from '@angular/common';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {


  faGoBack = faLongArrowAltLeft;

  constructor(public _location: Location, private router: Router) {}

  ngOnInit(): void {
  }

  goBack() {
    this._location.back();
  }

 

}
