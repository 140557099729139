import { Component, OnInit, Inject } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { RatingComponent } from '../../dialogs/rating/rating.component';
import { AddToCollectionComponent } from '../../dialogs/add-to-collection/add-to-collection.component';

@Component({
  selector: 'app-movie',
  templateUrl: './movie.component.html',
  styleUrls: ['./movie.component.css']
})

export class MovieComponent implements OnInit {

  guest = "";
  find = "";
  title = "";
  overview = "";
  poster = "";
  budget = "";
  release = "";
  revenue = "";
  vote_average = "";
  vote_count = "";
  languages = [];
  selected = '';

   constructor(private route:ActivatedRoute,private httpClient: HttpClient, private router: Router, public dialog: MatDialog ){}

  ngOnInit() {
    this.checkAuthentication();
    this.find = this.route.snapshot.params['id'];
    console.log (this.find);
    this.get_item();
  }

  requestAuthentication(){
    this.httpClient.get('https://api.themoviedb.org/3/authentication/guest_session/new?api_key=85204a8cc33baf447559fb6d51b18313').subscribe((res: any) => {
      console.log(res);
      localStorage.setItem('guestUser', res.guest_session_id);
  });
  }

  checkAuthentication(){
    if (!localStorage.getItem("guestUser")) {
      console.log('not set');
      this.requestAuthentication();
    }
    else {
      console.log(localStorage.getItem('guestUser'));
    }
  }

  get_item() {
    this.httpClient.get('https://api.themoviedb.org/3/movie/' + this.find + '?api_key=85204a8cc33baf447559fb6d51b18313&language=en-US').subscribe((res: any) => {
        console.log(res);
        this.title = res.original_title;
        this.overview = res.overview;
        this.poster = res.poster_path;
        this.budget = res.budget;
        this.release = res.release_date;
        this.revenue = res.revenue;
        this.vote_average = res.vote_average;
        this.vote_count = res.vote_count;
        this.languages = res.spoken_languages;
    });
}

openRatingDialog(): void {
  const dialogRef = this.dialog.open(RatingComponent, {
    width: '250px',
    data: {movie: this.find}
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
  });
}

openCollectionDialog(): void {
  const dialogRef = this.dialog.open(AddToCollectionComponent, {
    width: '250px',
    data: {movie: this.find, title: this.title, image: this.poster}
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
  });
}

}




