<ul class="nav nav-pills flex-column sticky-top" *ngIf="router.url.startsWith('/energy/')">
    <li class="nav-item">
      <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink ="energy/consumption" href="#">
        Consumption</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink ="energy/savings" href="#">
        Savings</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink ="energy/disaggregation" href="#">
        Disaggregation</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink ="energy/goals" href="#">
        Goals  &nbsp; <span class="badge badge-pill badge-danger">5</span></a>
    </li>
    <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink ="energy/meter-comparison" href="#">
          Meter Comparison</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink ="energy/peak-demand" href="#">
          Peak Demand</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink ="energy/typical-consumption" href="#">
          Typical Consumption</a>
    </li>
</ul>

<section class="card blue-box shadow py-4 px-4 mb-5" *ngIf="router.url == '/readings'">
  <ng-template #readingContent>
    <div class="text-left p-2 font-13" style="width: 600px">
    <p><strong>Instruction for registering your meter’s readings:</strong></p>
    <ul class="pl-3">
      <li>Enter the date on which you took your meter’s reading.</li>
      <li>Enter the consumption reading (kWh) as indicated on the meter, omitting the decimal digits.</li>
      <li>If you have a Reduced Night Tariff, then you must enter two readings – the reading indicated
        with K (Normal Day Tariff) and the reading indicated with M (Reduced Night Tariff), omitting
        the decimal digits that appear in a different colour.</li>
      <li>If you have two meters (for measuring active & reactive power), then you must enter one
        reading for the active power (kWh), one for the reactive power (kVArh) and one for the
        Chargeable Demand (if any).</li>
    </ul>
    <p><strong>If for any reason the consumption is measured at a time interval that is longer or shorter
      than four months, then the consumption charged is adjusted relatively to the four-month
      period by applying the “billing period adjustment factor” (actual consumption days / 120 days)</strong></p>
    </div>
  </ng-template>
  <p class="font-20">
    <strong>Enter new reading</strong> 
    <img src="assets/images/info_white.png" class="float-right pointer" style="margin-top:10px" alt="" [ngbTooltip]="readingContent" placement="bottom">
  </p>
  <p class="font-13">Register yourself the readings of your electricity
    meter and every month you will receive an
    ACTUAL bill based on your actual electricity
    consumption.</p>
    <p class="fill px-3 py-2 mt-4 ">Next measurement 01 Mar '20</p>
    <form action="" class="white-box px-4 py-4 shadow">
      <p>
        <mat-form-field appearance="legacy" class="custom-width">
          <mat-label>Date of reading</mat-label>
          <input matInput placeholder="Placeholder">
          <mat-hint>Please enter a valid date</mat-hint>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="legacy" class="custom-width">
          <mat-label>Day reading</mat-label>
          <input matInput placeholder="Placeholder">
          <mat-hint>Please enter a number in kWs</mat-hint>
        </mat-form-field>
      </p>      
      <p>
        <mat-form-field appearance="legacy" class="custom-width">
          <mat-label>Night reading</mat-label>
          <input matInput placeholder="Placeholder">
          <mat-hint>Please enter a number in kWs</mat-hint>
        </mat-form-field>
      </p>     
      <button class="btn btn-primary mt-4 font-13">
        Submit
      </button> 
    </form>
  </section>

<ul class="nav nav-pills flex-column sticky-top" *ngIf="router.url.startsWith('/bills/')">
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink ="bills/bill-analysis" href="#">
      Bill analysis</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink ="bills/explanation" href="#">
      Explanation</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink ="bills/payment" href="#">
      Payment</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink ="bills/e-bill" href="#">
      e-bill</a>
  </li>
</ul>

<ul class="nav nav-pills flex-column sticky-top" *ngIf="router.url == '/tips' || router.url.startsWith('/tips/')">
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink ="tips/latest" href="#">
      Latest</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink ="tips/demand-response" href="#">
      Demand response</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink ="tips/energy-efficiency" href="#">
      Energy efficiency</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink ="tips/enviroment-variables " href="#">
      Enviroment variables</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink ="tips/equipmentntools " href="#">
      Equipment & tools</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink ="tips/lighting " href="#">
      Lighting</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink ="tips/my-consumption " href="#">
      My consumption</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink ="tips/recycling " href="#">
      Recycling</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink ="tips/smart-building " href="#">
      Smart building</a>
  </li>
</ul>

<ul class="nav nav-pills flex-column sticky-top" *ngIf="router.url == '/meters' || router.url.startsWith('/meters/')">
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink ="meters/demographics" href="#">
      Demographics &nbsp; <span class="float-right">75%</span></a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink ="meters/building" href="#">
      Building &nbsp; <span class="float-right">63%</span></a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink ="meters/home-details" href="#">
      Home Details &nbsp; <span class="float-right">0%</span></a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink ="meters/shell-details " href="#">
      Shell Details &nbsp; <span class="float-right">0%</span></a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink ="meters/heating " href="#">
      Heating &nbsp; <span class="float-right">0%</span></a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink ="meters/cooling " href="#">
      Cooling &nbsp; <span class="float-right">0%</span></a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink ="meters/behavioral " href="#">
      Behavioral &nbsp; <span class="float-right">0%</span></a>
  </li>
</ul>