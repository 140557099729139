import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {Router,ActivatedRoute} from '@angular/router';
import {FormControl, Validators, NgForm} from '@angular/forms';

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.css']
})

export class CollectionsComponent implements OnInit {

  collections: Array<Object> = [];

  title = new FormControl('', [Validators.required]);
  description = new FormControl('', [Validators.required]);

  constructor(private _location: Location,  private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    console.log(localStorage.getItem('collections'));
    if (localStorage.getItem('collections')) this.collections = JSON.parse(localStorage.getItem('collections'));
  }

  getErrorMessage() {
    return this.title.hasError('required') ? 'You must enter a value' :'';
    return this.description.hasError('required') ? 'You must enter a value' :'';
  }

  onSubmit(form:NgForm){
    this.collections.push({"title":this.title.value, "description":this.description.value, "items":[]});
    localStorage.setItem('collections', JSON.stringify(this.collections));
    console.log (this.collections);
    form.resetForm();
    this.resetForm();
   }

   resetForm(){
    this.title.reset();
    this.description.reset();
   }

   spliceCollections(i){
    this.collections.splice(i, 1);
    localStorage.setItem('collections', JSON.stringify(this.collections));
   }

  goToCollection(i) {
    this.router.navigate(['collection/'+i]);
  }

}
