<div class="row">
    <div class="col-4 offset-4 px-0 pt-4">
        <div class="card blue-box mx-auto mb-0">
            <app-public-header></app-public-header>
         </div>
        <div class="card py-4 px-4 mb-5 dark-font">
            <img src="assets/images/logo2.png" class="mx-auto mt-3 mb-5" alt="">
            <p class="font-20"><strong>Reset password</strong></p>
            <p class="font-13">Please enter your email and tax identification number in the fields below to receive a unique link that will allow you to reset your password:</p>
            <form action="" class="mb-5">
            <p>
                <mat-form-field appearance="legacy" class="custom-width">
                <mat-label>Email</mat-label>
                <input matInput placeholder="">
                <mat-hint>Please enter your email</mat-hint>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="legacy" class="custom-width">
                <mat-label>Tax id</mat-label>
                <input matInput placeholder="">
                <mat-hint>Please enter your tax identification number</mat-hint>
                </mat-form-field>
            </p>   
            <button class="btn btn-primary mt-4 font-14" routerLink ="home">
                Submit
            </button> 
            </form>
            <hr>
            <p class="my-0">Return to the <a href="#" routerLink="login" ><u><strong>Login</strong></u></a> page</p>
        </div>
    </div>
</div>

<app-public-footer></app-public-footer>

