<section class="action">
    <div class="row">
       <div class="col-12">
          <p class="h1 text-center py-5">
             <img src="assets/images/home-light.png" class="mb-2 mr-4"> <strong class="foo font-40">76m<sup>2</sup> | 2 persons</strong>
          </p>
       </div>
    </div>
 </section>
 <section class="toolbox">
    <div class="row my-0">
       <div class="col text-right">
          <div ngbDropdown class="d-inline-block">
             <button class="btn btn-outline-light" id="dropdownBasic1" ngbDropdownToggle>Toggle views</button>
             <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="font-14">
                <button ngbDropdownItem (click) = "toggleAlert()">Toggle alert notification</button>
                <button ngbDropdownItem (click)="openSm(content)">Toggle modal notification</button>
                <button ngbDropdownItem (click) = "togglePayView()">Toggle current balance</button>
                <button ngbDropdownItem (click) = "togglePeerView()">Toggle peer/consumption view</button>
             </div>
          </div>
       </div>
    </div>
 </section>
 <section class="info mb-5">
    <div class="row">
       <div class="col-3">
          <div class="card fill mt-5 pt-5 px-2" style="height: 320px;">
             <p class="text-center">
                <strong class="font-17">Athens</strong>
                <br> <span class="font-13">Mon, 12:30 PM, Mostly Sunny</span>
             </p>
             <p class="font-50 text-center my-4">
                <strong>23<sup>o</sup>C</strong>
             </p>
             <p class="text-center">
                <span class="font-11"> 
                <img src="assets/images/drop.png" class="mb-1 mr-1"> 3% Precipitation 
                <img src="assets/images/leaf.png" class="ml-3 mr-1"> 21 km/h Winds
                </span>
             </p>
          </div>
       </div>
       <div class="col-6">
          <div class="alert alert-light alert-dismissible fade show py-3" role="alert" *ngIf="alertButton">
             Lorem ipsum dolor sit amet, consectetur adipiscing elit.
             <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click) = "toggleAlert()">
             <span aria-hidden="true"><img src="assets/images/dismiss.png"></span>            
             </button>
          </div>
          <div class="card fill pt-5 pb-4">
             <div class="row my-4">
                <div class="col-6">
                   <p class="text-center">
                      <img src="assets/images/lightning.png" style="margin-bottom:33px;" alt="">
                      <br> <strong class="font-17">Average Daily Consumption</strong>
                      <br> <strong class="font-50">11,3kWh</strong>
                   </p>
                </div>
                <div class="col-6" *ngIf = "peerView">
                   <ng-template #comparisonContent>
                      <div class="row" style="width: 300px;">
                         <div class="col-4 text-left">
                            <p>
                               Neighbors<br>
                               <strong>9,8kWh</strong>
                            </p>
                            <p>
                               1,15€
                            </p>
                         </div>
                         <div class="col-4 text-left">
                            <p>
                               You<br>
                               <strong>11,3kWh</strong>
                            </p>
                            <p>
                               1,50€
                            </p>
                         </div>
                         <div class="col-4 text-left">
                            <p>
                               Top 10%<br>
                               <strong>7,7kWh</strong>
                            </p>
                            <p>
                               0,90€
                            </p>
                         </div>
                      </div>
                   </ng-template>
                   <div class="comparison">
                      <img src="assets/images/peer.png" class="float-left pointer" style="margin-top: -23px;" [ngbTooltip]="comparisonContent " alt="" placement="bottom">
                   </div>
                </div>
                <div class="col-6 px-0" *ngIf = "!peerView">
                   <div class="consumption">
                      <ng-template #consumptionContent>
                         <p class="blue-text text-left">01/03/2020</p>
                         <p class="text-left">Daily consumption<br>
                            <strong>1.87kWh</strong>
                         </p>
                         <p class="text-left">
                            Difference from average<br>
                            <strong>
                               <fa-icon [icon]="faArrowUp" class="font-18 dark-font mr-1"></fa-icon>
                               12%
                            </strong>
                         </p>
                      </ng-template>
                      <img src="assets/images/consumption1.png" class="float-right mr-5" style="margin-top: -4px;" alt="">
                      <img src="assets/images/consumption2.png" class="float-right img-fluid mt-3 mr-3 pointer" [ngbTooltip]="consumptionContent" alt="" placement="left">
                   </div>
                </div>
             </div>
          </div>
       </div>
       <div class="col-3" *ngIf = "!payView">
          <div class="card fill mt-5 mb-4 pt-5 px-3" style="height: 320px;">
             <p class="text-center">
                <strong class="font-17">Current Balance</strong>
                <br><span class="font-13">Last bill type: Genius</span>
                <br><span class="font-13">Issued: 25/02/2020</span>
             </p>
             <p class="font-50 text-center mt-0 mb-4 py-2 fill-more">
                <strong>0,00€</strong>
             </p>
             <p class="text-center">
                <span class="font-13">Last payment: 27/02/2020</span>
             </p>
          </div>
       </div>
       <div class="col-3" *ngIf = "payView">
          <div class="card fill mt-5 mb-4 pt-5 px-3" style="height: 320px;">
             <p class="text-center">
                <strong class="font-17">Current Balance</strong>
                <br><span class="font-13">Last bill type: Genius</span>
                <br><span class="font-13">Issued: 25/02/2020</span>
             </p>
             <p class="font-50 text-center mt-0 mb-4 py-2 box-blue">
                <strong>48,35€</strong>
             </p>
             <p class="text-center">
                <button class="btn btn-light">
                Pay now
                </button> 
             </p>
          </div>
       </div>
    </div>

    <ng-template #content let-modal>
        <div class="modal-body" class="px-0 mx-auto dark-text" style="width: 350px;">
           <div class="card" class="px-0">
              <button type="button" class="close close-modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true"><img src="assets/images/wclose.png" alt=""></span>
              </button>
              <img src="assets/images/max-bender.png" class="card-img-top" alt="...">
              <div class="card-body px-4 mt-3">
                 <p class="mb-0"><strong>Title</strong></p>
                 <p class="card-text mt-0">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                 <button type="button" class="btn box-blue mt-3 font-14 px-4" (click)="modal.close('Save click')"><strong>Ok</strong></button>
              </div>
           </div>
        </div>
     </ng-template>

 </section>
