<div class="card box-white shadow mb-5 px-4 py-4">

    <div class="row">
      <div class="col-12">
        <p class="font-20"><strong>History</strong></p>
      </div>
    </div>
    
    <div class="row mt-3">
      <div class="col-12">
        <mat-form-field style="width: 350px;">
          <mat-label>See results for</mat-label>
          <mat-select [(ngModel)]="selectedValue">
            <mat-option *ngFor="let result of results" [value]="result.value">
              {{result.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    
    <div class="row mt-3 mb-3">
        <div class="col-12">
            <mat-accordion>
                
                 <!-- ==================================== -->
                 <mat-expansion-panel [disabled]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="font-11">
                            DATE OF READING
                        </mat-panel-title>
                        <mat-panel-description class="font-11">
                           DAY READING
                        </mat-panel-description>
                        <mat-panel-description class="font-11">
                            NIGHT READING
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                </mat-expansion-panel>

                <!-- ==================================== -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            01 Nov '19
                        </mat-panel-title>
                        <mat-panel-description>
                            24.901
                        </mat-panel-description>
                        <mat-panel-description>
                            9.350
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                </mat-expansion-panel>

                <!-- ==================================== -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            01 Dec '19
                        </mat-panel-title>
                        <mat-panel-description>
                            24.183
                        </mat-panel-description>
                        <mat-panel-description>
                            9.116
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                </mat-expansion-panel>
                <!-- ==================================== -->
                <!-- <mat-expansion-panel (opened)="panelOpenState = true"
                                     (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Self aware panel
                    </mat-panel-title>
                    <mat-panel-description>
                      Currently I am {{panelOpenState ? 'open' : 'closed'}}
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <p>I'm visible because I am open</p>
                </mat-expansion-panel> -->

                <!-- ==================================== -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            01 Jan '20
                        </mat-panel-title>
                        <mat-panel-description>
                            1.079
                        </mat-panel-description>
                        <mat-panel-description>
                            193
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                </mat-expansion-panel>
              </mat-accordion>
              
        </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <mat-paginator [length]="100"
              [pageSize]="10"
              [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>
      </div>
    </div>
  
  </div>
