<div class="card box-white shadow mb-5 px-4 pt-4 pb-5">
    <div class="row">
        <div class="col-12">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1">
                  <a ngbNavLink>Page 1</a>
                  <ng-template ngbNavContent>
                    <img src="assets/images/bill_page_1.jpg" class="img-fluid" alt="">
                  </ng-template>
                </li>
                <li [ngbNavItem]="2">
                  <a ngbNavLink>Page 2</a>
                  <ng-template ngbNavContent>
                    <img src="assets/images/bill_page_2.jpg" class="img-fluid" alt="">
                  </ng-template>
                </li>
              </ul>
              
              <div [ngbNavOutlet]="nav"></div>
              
           
        </div>
    </div>
</div>
