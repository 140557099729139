<header class="py-1" *ngIf="router.url != '/' 
&& !router.url.startsWith('/welcome/') 
&& !router.url.startsWith('/profile/')">
  <div class="container">
    <!-- start component -->
    <app-header></app-header>
    <!-- end component -->
  </div>   
</header>

<main class="">

  <!-- start general dark division: show in all pages except home and guest pages -->
  <div class="dark pt-4 pb-5" *ngIf="router.url != '/' 
  && !router.url.startsWith('/welcome/')">
    <section class="info mb-4">
      <div class="container">
        <!-- start component -->
        <app-quick-info></app-quick-info>
        <!-- end component -->
      </div>
    </section>
  </div>
  <!-- end general dark division-->

   <!-- start login dark division: show only in login page -->
   <div class="dark pt-5" *ngIf="router.url == '/welcome/login'">
      <section class="login" >
        <div class="container">
          <!-- start component -->
          <app-login></app-login>
          <!-- end component -->
        </div>   
      </section>
      <section class="cover"></section>
    </div>
    <!-- end login dark division-->

   <!-- start register dark division: show only in register page -->
   <div class="dark pt-5" *ngIf="router.url == '/welcome/register'">
    <section class="login" >
      <div class="container">
        <!-- start component -->
        <app-register></app-register>
        <!-- end component -->
      </div>   
    </section>
    <section class="cover"></section>
  </div>
  <!-- end register dark division-->

 <!-- start reset-password dark division: show only in reset-password page -->
 <div class="dark pt-5" *ngIf="router.url == '/welcome/reset-password'">
  <section class="login" >
    <div class="container">
      <!-- start component -->
      <app-reset-password></app-reset-password>
      <!-- end component -->
    </div>   
  </section>
  <section class="cover"></section>
</div>
<!-- end reset-password dark division-->

 <!-- start terms dark division: show only in terms page -->
 <div class="dark pt-5" *ngIf="router.url == '/welcome/terms'">
  <section class="login" >
    <div class="container">
      <!-- start component -->
      <app-terms></app-terms>
      <!-- end component -->
    </div>   
  </section>
  <section class="cover"></section>
</div>
<!-- end terms dark division-->

  <!-- start home dark division: show only in home page -->
  <div class="dark pt-4" *ngIf="router.url == '/'">
    <section class="home" >
      <div class="container">
        <!-- start component -->
        <app-home></app-home>
        <!-- end component -->
      </div>   
    </section>

    <section class="navigate">
      <div class="container">
        <!-- start component -->
        <app-navigate></app-navigate>
        <!-- end component -->
      </div>
    </section>
    <section class="cover"></section>
  </div>
  <!-- end home dark division-->

  <!-- start general light division: show in all pages except home -->
  <div class="light" *ngIf="router.url != '/' 
  && !router.url.startsWith('/welcome/')">

    <section class="navigate sticky-top">
      <div class="container">
        <!-- start component -->
        <app-navigate></app-navigate>
        <!-- end component -->
      </div>
    </section>

    <section class="route">
      <div class="container">

        <div class="row" *ngIf="router.url == '/readings'">
          <div class="col-4" >
            <!-- start component -->
            <app-sidebar></app-sidebar>
            <!-- end component -->
          </div>
          
          <div class="col-8">
            <!-- start component -->
            <router-outlet></router-outlet>
            <!-- end component -->
          </div>
        </div>

        <div class="row" *ngIf="router.url == '/profile/help'">
          <div class="col-12">
            <!-- start component -->
            <router-outlet></router-outlet>
            <!-- end component -->
          </div>
        </div>

          <div class="row" *ngIf="router.url != '/readings' 
          && router.url != '/profile/help'">
            <div class="col-2">
              <!-- start component -->
              <app-sidebar></app-sidebar>
              <!-- end component -->
            </div>

            <div class="col-10">
              <!-- start component -->
              <router-outlet></router-outlet>
              <!-- end component -->
            </div>
          </div>

      </div> 
    </section>

  </div>
  <!-- end general light division-->
</main>

<google-chart style="width: 100%; height: 500px;" class="d-none" #chart 
[title]="title"
[type]="type"
[data]="data"
[options]="options"
>
</google-chart>

<footer class="bottom" *ngIf="router.url != '/' 
&& !router.url.startsWith('/welcome/')">
  <div class="container">
    <!-- start component -->
    <app-footer></app-footer>
    <!-- end component -->
  </div>
</footer>