<div class="card box-white shadow mb-5 px-5 py-4">

    <div class="row">
      <div class="col-12">
        <p class="font-20"><strong>Bill Analysis</strong></p>
      </div>
    </div>
    
    <div class="row mt-3">
      <div class="col-12">
        <img src="assets/images/energy0.png" class="img-fluid" alt="">
      </div>
    </div>

    <div class="row mt-3">
        <div class="col-4 offset-8">
          <img src="assets/images/analysis1.png" class="img-fluid" alt="">
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <img src="assets/images/analysis2.png" class="img-fluid" alt="">
        </div>
      </div>

      <div class="row mt-5">
          <div class="col-12">

            <table class="table table-striped">
              <thead>
              <tr>
                <th scope="col">ISSUE DATE</th>
                <th scope="col">BILLING PERIOD</th>
                <th scope="col">DUE DATE</th>
                <th scope="col">TOTAL COST</th>
                <th scope="col">PAYMENT</th>
                <th scope="col"></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let bill of bills; index as i">
                <td scope="row">{{ bill.issue }}</td>
                <td>{{  bill.period }}</td>
                <td>{{ bill.due }}</td>
                <td>{{ bill.amount }}</td>
                <td>{{ bill.payment }}</td>
                <td><img src="assets/images/download.png" alt="" class="pointer"></td>
              </tr>
              </tbody>
            </table>
          </div>
      </div>
    <div class="row mt-3">
      <div class="col-12">
        <mat-paginator [length]="100"
              [pageSize]="10"
              [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>
      </div>
    </div>
  
  </div>
