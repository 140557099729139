<p class="text-center">Please enter at least three characters in the input bellow
        <br> to search for a movie title. Only alphanumeric characters are allowed.</p>
    <div class="row">
        <div class="col-lg-6 offset-md-3">
            <form (ngSubmit)="onSubmit()">
                <div class="example-container">
                    <mat-form-field>
                        <input matInput placeholder="Search for movie title" [formControl]="search" name="search">
                        <mat-error *ngIf="search.invalid">{{getErrorMessage()}}</mat-error>
                    </mat-form-field>
                </div>
                <button type="submit" mat-button [disabled]="search.invalid" class="float-right">Search &#10095;</button>
            </form>
        </div>
    
    </div>