import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {Location} from '@angular/common';
import { faThumbsUp, faLongArrowAltLeft} from '@fortawesome/free-solid-svg-icons';

interface Result {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {

  faLike = faThumbsUp;
  faGoBack = faLongArrowAltLeft;
  
  results: Result[] = [
    {value: '1', viewValue: 'Category 1'},
    {value: '2', viewValue: 'Category 2'},
    {value: '3', viewValue: 'Category 3'}
  ];

  constructor(public _location: Location, private router: Router) {}

  ngOnInit(): void {
  }

  goBack() {
    this._location.back();
  }

}
