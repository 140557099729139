<div class="row">
    <div class="col-5">
        <div class="card box-white shadow mb-5 px-4 py-4">

            <div class="row">
              <div class="col-12">
                <p class="font-20"><strong>Typical consumption per day</strong></p>
              </div>
            </div>

            <div class="row mt-3">
                <div class="col-12">
                  <img src="assets/images/energy0.png" class="img-fluid" alt="">
                </div>
              </div>
            
            <div class="row mt-3">
              <div class="col-12">
                <img src="assets/images/typical1.png" class="img-fluid" alt="">
              </div>
            </div>
            
            <div class="row mt-4">
                <div class="col-12">
                    <img src="assets/images/typical2.png" class="img-fluid" alt="">
                </div>
            </div>
          
          </div>
    </div>

    <div class="col-7">
        <div class="card box-white shadow mb-5 px-4 py-4">

            <div class="row">
              <div class="col-12">
                <p class="font-20"><strong>Typical consumption per hour of day</strong></p>
              </div>
            </div>
            
            <div class="row mt-3">
              <div class="col-12">
                <img src="assets/images/energy0.png" class="img-fluid" alt="">
              </div>
            </div>
            
            <div class="row my-4">
                <div class="col-12">
                    <img src="assets/images/typical3.png" class="img-fluid" alt="">
                </div>
            </div>
          
          </div>
    </div>
</div>


