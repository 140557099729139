
    
    <div class="row">
        <div class="col-md-4">
            <p class="text-center lead">Create new collection</p>
            <p class="text-center">Please enter a title and a description for the
                <br> new collection in the inputs bellow </p>
            <form (ngSubmit)="onSubmit(f)" #f="ngForm">
                <div class="example-container">
                    <mat-form-field>
                        <input matInput placeholder="Title" [formControl]="title" name="title">
                        <mat-error *ngIf="title.invalid">{{getErrorMessage()}}</mat-error>
                    </mat-form-field>
    
                    <mat-form-field>
                        <textarea matInput placeholder="Description" [formControl]="description" name="description"></textarea>
                        <mat-error *ngIf="description.invalid">{{getErrorMessage()}}</mat-error>
                    </mat-form-field>
    
                </div>
                <div class="row">
                    <div class="col-6">
                        <p class="text-left">
                            <button mat-button type="button" (click)="resetForm()">&#x2715; Reset
                            </button>
                        </p>
                    </div>
                    <div class="col-6">
                        <p class="text-right">
                            <button mat-button type="submit" [disabled]="title.invalid || description.invalid">&#x2713; Create
                            </button>
                        </p>
                    </div>
                </div>
    
            </form>
    
        </div>
        <div class="col-md-8">
            <p class="text-center lead">Collections list</p>
            <p class="text-center">Bellow you can see all the collections
                <br> you have created</p>
            <mat-list>
                <mat-list-item *ngFor="let collection of collections; let i = index">
                    <h2 matLine class="pointer" (click)="goToCollection(i)"> <strong>{{collection.title}}</strong> &#x2192;</h2>
                    <p matLine>
                        <span> {{collection.description}} </span>
                        <span class="float-right pointer" (click)="spliceCollections(i)">&#x2715; delete</span>
                    </p>
                    <hr>
                </mat-list-item>
            </mat-list>
        </div>
    
    </div>
