<div class="card box-white shadow mb-5 px-5 pt-5 pb-0">

    <div class="row">
      <div class="col-8">
        <p class="font-20"><strong>How disaggregation works</strong></p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In cursus lectus erat, eget
            scelerisque lorem scelerisque pretium.</p>
      </div>
      <div class="col-4">
        <button type="button" class="btn btn-primary btn-lg px-5 float-right mt-4" (click)="openLg(content)"> 
            <strong class="font-14">+ Add your first device</strong>
        </button>
      </div>
    </div>
    <hr>
    <div class="row">
          <!-- =========================== -->
        <div class="col-3 text-center">
            <div class="py-4">
                <img src="assets/images/teapot.png" class="mb-3" alt="">
                <p class="font-13">Add your appliances<br>
                    in the disaggregation<br>
                    wizard.</p>
            </div>
        </div>
        <div class="col-1">
            <div class="py-auto">
                <img src="assets/images/arrow-left.png" class="mt-5" alt="">
            </div>
        </div>
        <!-- =========================== -->
        <div class="col-4 text-center px-0">
            <div class="py-4">
                <img src="assets/images/timer.png" class="mb-3" alt="">
                <p class="font-13">Update the metadata of each
                    appliance along with the average hours
                    of usage of each appliance per week.</p>
            </div>
        </div>
        <div class="col-1">
            <div class="py-auto">
                <img src="assets/images/arrow-left.png" class="mt-5" alt="">
            </div>
        </div>
        <!-- =========================== -->
        <div class="col-3 text-center">
            <div class="py-4">
                <img src="assets/images/pie.png" class="mb-3" alt="">
                <p class="font-13">This data will be used to analyze your
                    consumption on appliance level
                    accuracy at the end of the week.</p>
            </div>
        </div>

    </div>
    
  </div>

  <ng-template #content let-modal NgbActiveModal>
    <div class="modal-body">
       <div class="card" class="p-4 dark-text">

           <div class="row">
               <div class="col-8">
                <p class="font-20"><strong>Add device</strong></p>
               </div>
               <div class="col-4">
                <button type="button" class="close close-modal" style="margin-top: 3px;" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true"><img src="assets/images/dclose.png" alt=""></span>
                    </button>
               </div>
           </div>
         
          <button mat-raised-button (click)="isLinear = !isLinear" id="toggle-linear" class="d-none">
            {{!isLinear ? 'Enable linear mode' : 'Disable linear mode'}}
          </button>

        <mat-vertical-stepper [linear]="isLinear" #stepper>

            <mat-step [stepControl]="firstFormGroup">
              <form [formGroup]="firstFormGroup">
                <ng-template matStepLabel>Room</ng-template>
                <mat-form-field appearance="legacy" class="custom-width">
                  <mat-label>Select</mat-label>
                  <input matInput placeholder="" formControlName="firstCtrl" required>
                </mat-form-field>
                <div>
                  <button mat-button matStepperNext>Continue</button>
                </div>
              </form>
            </mat-step>

            <mat-step [stepControl]="secondFormGroup">
              <form [formGroup]="secondFormGroup">
                <ng-template matStepLabel>Device</ng-template>
                <mat-form-field appearance="legacy" class="custom-width">
                  <mat-label>Name</mat-label>
                  <input matInput formControlName="secondCtrl" placeholder=""
                         required>
                </mat-form-field>
                <div>
                    <button mat-button matStepperNext>Continue</button>
                </div>
              </form>
            </mat-step>

            <mat-step>
                <form [formGroup]="thirdFormGroup">
              <ng-template matStepLabel>Energy</ng-template>
              <div class="row">
                  <div class="col-6">
                    <mat-form-field appearance="legacy" class="custom-width">
                        <mat-label>Device kW (> 1)</mat-label>
                        <input matInput placeholder="" formControlName="firstCtrl" required>
                      </mat-form-field>
                  </div>

                  <div class="col-6">
                    <mat-form-field appearance="legacy" class="custom-width">
                        <mat-label>Active hours per day (> 0.1)</mat-label>
                        <input matInput placeholder="" formControlName="firstCtrl" required>
                      </mat-form-field>
                  </div>
              </div>
             
              <div>
                <button mat-button (click)="stepper.reset()">Add device</button>
              </div>
            </form>
            </mat-step>
          </mat-vertical-stepper>
        
       </div>
    </div>
 </ng-template>