<div class="row">
    <div class="col-12"  [innerHtml]="htmlToAdd">
       
    </div>
</div>

<mat-list>
    <div class="row">
        <mat-list-item *ngFor="let result of results" class="col-md-4 pointer" id="{{result.id}}" (click)="navigateMovie(result.id)">
            <img matListAvatar src="https://image.tmdb.org/t/p/w500/{{result.poster_path}}" onError="this.src='https://www.mitromaras.gr/novibet-angular/assets/img/image-placeholder.png';" alt="...">
            <h3 matLine>  {{result.title}} </h3>
            <p matLine>
                <span> <small>rating:</small> {{result.vote_average}} </span>
            </p>
            <p matLine></p>
        </mat-list-item>
    </div>
</mat-list>

<div class="row">
    <div class="col-12">
        <mat-paginator 
        [length]=total_results 
        [pageSize]="20" 
        [pageSizeOptions]="[]" 
        (page)="getPaginatorData($event)">
        </mat-paginator>
    </div>
</div>