import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { faArrowCircleUp} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  faArrowUp = faArrowCircleUp;
  closeResult = '';
  alertButton: Boolean = false;
  payView: Boolean = false;
  peerView: Boolean = true;

   constructor(private modalService: NgbModal) {}

  ngOnInit(): void {
  }

  openSm(content) {
    this.modalService.open(content, { size: 'sm' });
  }
  
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  toggleAlert() {
      if (this.alertButton == false) {
        this.alertButton = true;
      }
      else {
        this.alertButton = false;
      }     
  }

  togglePayView() {
    if (this.payView == false) {
      this.payView = true;
    }
    else {
      this.payView = false;
    }     
  }

  togglePeerView() {
    if (this.peerView == false) {
      this.peerView = true;
    }
    else {
      this.peerView = false;
    }     
  }
}
