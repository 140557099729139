import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ConsumptionComponent } from './energy/consumption/consumption.component';
import { SavingsComponent } from './energy/savings/savings.component';
import { DisaggregationComponent } from './energy/disaggregation/disaggregation.component';
import { GoalsComponent } from './energy/goals/goals.component';
import { MeterComparisonComponent } from './energy/meter-comparison/meter-comparison.component';
import { PeakDemandComponent } from './energy/peak-demand/peak-demand.component';
import { TypicalConsumptionComponent } from './energy/typical-consumption/typical-consumption.component';
import { ReadingsComponent } from './readings/readings.component';
import { MoviesComponent } from './tests/movies/movies.component';
import { MovieComponent } from './tests/movie/movie.component';
import { TipsComponent } from './tips/tips.component';
import { BillAnalysisComponent } from './bills/bill-analysis/bill-analysis.component';
import { ExplanationComponent } from './bills/explanation/explanation.component';
import { PaymentComponent } from './bills/payment/payment.component';
import { EBillComponent } from './bills/e-bill/e-bill.component';
import { MeterProfileComponent } from './meter-profile/meter-profile.component';
import { HelpComponent } from './profile/help/help.component';
import { AccountComponent } from './profile/account/account.component';
import { PreferencesComponent } from './profile/preferences/preferences.component';
import { SecurityComponent } from './profile/security/security.component';
import { SettingsComponent } from './profile/settings/settings.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', redirectTo: '/', pathMatch: 'full' },
  { path: 'login', redirectTo: '/welcome/login', pathMatch: 'full' },
  { path: 'register', redirectTo: '/welcome/register', pathMatch: 'full' },
  { path: 'terms', redirectTo: '/welcome/terms', pathMatch: 'full' },
  { path: 'reset-password', redirectTo: '/welcome/reset-password', pathMatch: 'full' },
  { path: 'energy', redirectTo: 'energy/consumption', pathMatch: 'full' },
  { path: 'energy/consumption', component: ConsumptionComponent },
  { path: 'energy/savings', component: SavingsComponent },
  { path: 'energy/disaggregation', component: DisaggregationComponent },
  { path: 'energy/goals', component: GoalsComponent },
  { path: 'energy/meter-comparison', component: MeterComparisonComponent },
  { path: 'energy/peak-demand', component: PeakDemandComponent },
  { path: 'energy/typical-consumption', component: TypicalConsumptionComponent },
  { path: 'bills', redirectTo: 'bills/bill-analysis', pathMatch: 'full' },
  { path: 'bills/bill-analysis', component: BillAnalysisComponent },
  { path: 'bills/explanation', component: ExplanationComponent },
  { path: 'bills/payment', component: PaymentComponent },
  { path: 'bills/e-bill', component: EBillComponent },
  { path: 'readings', component: ReadingsComponent },
  { path: 'tips', redirectTo: 'tips/latest', pathMatch: 'full' },
  { path: 'tips/:title', component: TipsComponent },
  { path: 'meters', redirectTo: 'meters/demographics', pathMatch: 'full' },
  { path: 'meters/:title', component: MeterProfileComponent },
  { path: 'help', redirectTo: 'profile/help', pathMatch: 'full' },
  { path: 'profile/help', component: HelpComponent },
  { path: 'account', redirectTo: 'profile/account', pathMatch: 'full' },
  { path: 'profile/account', component: AccountComponent },
  { path: 'preferences', redirectTo: 'profile/preferences', pathMatch: 'full' },
  { path: 'profile/preferences', component: PreferencesComponent },
  { path: 'security', redirectTo: 'profile/security', pathMatch: 'full' },
  { path: 'profile/security', component: SecurityComponent},
  { path: 'settings', redirectTo: 'profile/settings', pathMatch: 'full' },
  { path: 'profile/settings', component: SettingsComponent},
  {path:'**', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
