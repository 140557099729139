import { Component, OnInit } from '@angular/core';

interface Bill {
  issue: string;
  period: string;
  due: string;
  amount: string;
  payment: string;
}

const BILLS: Bill[] = [
  {
    issue: '01 Mar ‘20',
    period: '01 Jan ‘20 to 29 Feb ‘20',
    due: '20 Mar ‘20',
    amount: '67,35€',
    payment: 'Pending'
  },
  {
    issue: '01 Mar ‘20',
    period: '01 Jan ‘20 to 29 Feb ‘20',
    due: '20 Mar ‘20',
    amount: '67,35€',
    payment: 'Pending'
  },
  {
    issue: '01 Mar ‘20',
    period: '01 Jan ‘20 to 29 Feb ‘20',
    due: '20 Mar ‘20',
    amount: '67,35€',
    payment: 'Pending'
  },
  {
    issue: '01 Mar ‘20',
    period: '01 Jan ‘20 to 29 Feb ‘20',
    due: '20 Mar ‘20',
    amount: '67,35€',
    payment: 'Pending'
  },
  {
    issue: '01 Mar ‘20',
    period: '01 Jan ‘20 to 29 Feb ‘20',
    due: '20 Mar ‘20',
    amount: '67,35€',
    payment: 'Pending'
  },
  {
    issue: '01 Mar ‘20',
    period: '01 Jan ‘20 to 29 Feb ‘20',
    due: '20 Mar ‘20',
    amount: '67,35€',
    payment: 'Pending'
  },
  {
    issue: '01 Mar ‘20',
    period: '01 Jan ‘20 to 29 Feb ‘20',
    due: '20 Mar ‘20',
    amount: '67,35€',
    payment: 'Pending'
  },
  {
    issue: '01 Mar ‘20',
    period: '01 Jan ‘20 to 29 Feb ‘20',
    due: '20 Mar ‘20',
    amount: '67,35€',
    payment: 'Pending'
  },
  {
    issue: '01 Mar ‘20',
    period: '01 Jan ‘20 to 29 Feb ‘20',
    due: '20 Mar ‘20',
    amount: '67,35€',
    payment: 'Pending'
  },
  {
    issue: '01 Mar ‘20',
    period: '01 Jan ‘20 to 29 Feb ‘20',
    due: '20 Mar ‘20',
    amount: '67,35€',
    payment: 'Pending'
  },
];

@Component({
  selector: 'app-bill-analysis',
  templateUrl: './bill-analysis.component.html',
  styleUrls: ['./bill-analysis.component.css']
})
export class BillAnalysisComponent implements OnInit {

  bills = BILLS;

  constructor() { }

  ngOnInit(): void {
  }

}
