<div class="row">
    <div class="col-4 offset-4 px-0 pt-4">
        <div class="card blue-box mx-auto mb-0">
           <app-public-header></app-public-header>
        </div>
        <div class="card py-4 px-4 mb-5 dark-font">
            <img src="assets/images/logo2.png" class="mx-auto mt-3 mb-5" alt="">
            <p class="font-20"><strong>Login</strong></p>
            <form action="" class="mb-5">
            <p>
                <mat-form-field appearance="legacy" class="custom-width">
                <mat-label>Email</mat-label>
                <input matInput placeholder="">
                <mat-hint>Please enter a valid email</mat-hint>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="legacy" class="custom-width">
                <mat-label>Password</mat-label>
                <input matInput placeholder="">
                <mat-hint>Please enter your password</mat-hint>
                </mat-form-field>
            </p>   

            <div class="row mt-4">
                <div class="col-5 mx-0">
                    <p><mat-checkbox> Remember me</mat-checkbox></p>     
                </div>
                <div class="col-7 mx-0">
                    <p class="text-right">
                        <a href="#" routerLink="reset-password" class="dark-font"><u>I forgot my password?</u></a>
                    </p>     
                </div>
            </div>   
           
            <button class="btn btn-primary mt-3 font-14" routerLink ="home">
                Sign in
            </button> 
            <p class="font-13 mt-4">By signing in, I accept the 
                <a href="#" class="dark-font" routerLink="terms"><u>Terms & Conditions</u></a>
            </p>
            </form>
            <hr>
            <p class="my-0">Don't have an account? <a href="#" routerLink="register" ><u><strong>Register</strong></u></a></p>
        </div>
    </div>
</div>

<app-public-footer></app-public-footer>

