<div class="row mb-4" *ngIf = "!router.url.startsWith('/profile/')">
    <div class="col-12">
        <ul class="nav nav-white nav-pills nav-fill justify-content-center mb-4 px-3 py-3">
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" routerLink ="energy" href="#">
                My energy</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" routerLink ="readings" href="#">
                Meter readings</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" routerLink ="bills" href="#">
                Bills</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" routerLink ="tips" href="#">
                Tips &nbsp; <span class="badge badge-pill badge-danger">5</span></a>
            </li>
            <ng-template #tipContent>
              <div class="box-white text-center" style="width: 450px">
                Complete meter profile and <strong>get fully personalized</strong> reports and tips
              </div>
            </ng-template>
            <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" routerLink ="meters" href="#" placement="top" [ngbTooltip]="tipContent">
                  Meter profile</a>
              </li>
              <li class="nav-item">
                <a class="nav-link disabled" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" routerLink ="loyalty" href="#">
                  Loyalty</a>
              </li>
          </ul>
    </div>
</div>

<app-public-footer *ngIf="router.url == '/'"></app-public-footer>

