<div class="card box-white shadow mb-5">

  <div class="row">
    <div class="col-12">
      <p class="font-20"><strong>Energy measurements from bills</strong></p>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc maximus viverra urna quis interdum. Curabitur in sem
        semper, viverra magna vel, rhoncus mi.</p>
    </div>
  </div>
  
  <div class="row mt-3">
    <div class="col-5">
      <img src="assets/images/energy0.png" class="img-fluid" alt="">
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-8">

    </div>
    <div class="col-4">
      <img src="assets/images/energy1.png" class="img-fluid" alt="">
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12">
      <img src="assets/images/energy2.png" class="img-fluid" alt="">
    </div>
  </div>

</div>