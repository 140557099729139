<nav class="navbar navbar-expand-lg navbar-light" ngbNav #nav="ngbNav">
    <a class="navbar-brand" href="#" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink = "">
        <img src="assets/images/logo.png" alt="">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">

        <li ngbDropdown class="nav-item mr-2">
            <a href (click)="false" class="nav-link" ngbDropdownToggle>
                <fa-icon [icon]="selectedIcon" class="font-20 dark-font mr-1"></fa-icon>
                {{selectedMeter}}
            </a>
            <div ngbDropdownMenu class="py-4 dropdown-meters">
                    <div class="row">
                        <div class="col-6">
                            <p class="font-11 dark-font py-0 my-0 pl-3"> ELECTRICITY SUPPLIES</p>
                        </div>
                        <div class="col-6">
                            <p class="font-11 dark-font py-0 my-0"> &nbsp; NATURAL GAS SUPPLIES</p>
                        </div>
                    </div>
                    <div class="dropdown-divider"></div>
                    <div class="row font-14">
                    <div class="col-6">
                        <button ngbDropdownItem class="pl-3 dark-font" (click)="selectMeter(faHome,'43032740033 - My House')">
                            <fa-icon [icon]="faHome" class="font-18 mr-1"></fa-icon> 
                            43032740033 - My House</button>
                        <div class="dropdown-divider"></div>
                        <button ngbDropdownItem class="pl-3 dark-font" (click)="selectMeter(faOffice,'43032740034 - My Office')">
                            <fa-icon [icon]="faOffice" class="font-18 mr-1"></fa-icon> 
                            43032740034 - My Office</button>
                        <div class="dropdown-divider"></div>
                        <button ngbDropdownItem class="pl-3 dark-font" (click)="selectMeter(faFactory,'43032740035 - My Factory')">
                            <fa-icon [icon]="faFactory" class="font-18 mr-1"></fa-icon> 
                            43032740035 - My Factory</button>
                    </div>
                    <div class="col-6">
                        <button ngbDropdownItem class="pl-1 dark-font" (click)="selectMeter(faHome,'43032740036 - My Cottage')">
                            <fa-icon [icon]="faHome" class="font-18 mr-1" ></fa-icon> 
                            43032740036 - My Cottage</button>
                        <div class="dropdown-divider"></div>
                        <button ngbDropdownItem class="pl-1 dark-font" (click)="selectMeter(faStore,'43032740037 - My Store')">
                            <fa-icon [icon]="faStore" class="font-18 mr-1"></fa-icon> 
                            43032740037 - My Store</button>
                    </div>
                </div>
            </div>
          </li>

        <li class="nav-item mr-2">
          <a class="nav-link" href="#" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink = "/help"> 
            <fa-icon [icon]="faQuestionCircle" class="font-20 dark-font mt-0 mr-1"></fa-icon> Help & Support
            </a>
        </li>
        <li class="nav-item mr-2">
            <a class="nav-link" (click) = "false" href="#" routerLinkActive="active"> 
                <fa-icon [icon]="faBook" class="font-20 dark-font mr-1"></fa-icon>
              </a>
          </li>

          <li ngbDropdown class="nav-item bell">
            <a href (click)="false" class="nav-link" ngbDropdownToggle>
                <fa-icon [icon]="faBell" class="font-20 dark-font mr-1"></fa-icon>
            </a>
            <div class="dropdown-menu py-4 dark-text shadow" aria-labelledby="navbarDropdown" ngbDropdownMenu>
              <p class="px-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>
                <span class="mute-text">07/03/2020</span></p>
              <div class="dropdown-divider"></div>
              <p class="px-4">Nullam varius tellus et odio condimentum, quis ultricies augue eleifend.<br>
                <span class="mute-text">02/03/2020</span></p>
              <div class="dropdown-divider"></div>
              <p class="px-4">Aliquam a lorem at arcu dignissim pulvinar ut sit amet sem.<br>
                <span class="mute-text">25/02/2020</span></p>
            </div>
          </li>

          <li ngbDropdown class="nav-item ml-1" style="margin-top: -3px;">
            <a href (click)="false" class="nav-link" ngbDropdownToggle>
                <fa-icon [icon]="faUser" class="font-24 dark-font mr-1"></fa-icon>
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown" ngbDropdownMenu>
              <a class="dropdown-item disabled font-11" ngbDropdownItem href="#">username@intelen.com</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" ngbDropdownItem href="#" routerLink = "/profile/account">Profile</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#" routerLink = "/login">Logout</a>
            </div>
          </li>
       
      </ul>
    </div>
  </nav>

