<div class="card box-white shadow mb-5 px-5 py-5">

    <div class="row">
      <div class="col-12">
        <p class="font-20"><strong>Similar meter comparison</strong></p>
      </div>
    </div>
    
    <div class="row mt-3">
      <div class="col-5">
        <img src="assets/images/energy0.png" class="img-fluid" alt="">
      </div>
    </div>
  
    <div class="row mt-3">
      <div class="col-6">
  
      </div>
      <div class="col-6">
        <img src="assets/images/comparison1.png" class="img-fluid" alt="">
      </div>
    </div>
  
    <div class="row mt-3">
      <div class="col-12">
        <img src="assets/images/comparison2.png" class="img-fluid" alt="">
      </div>
    </div>
  
  </div>
