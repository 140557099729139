import { Component, OnInit, Inject } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.css']
})

export class RatingComponent implements OnInit {

  session = localStorage.getItem('guestUser');
  selected = '';
  movie = '';

  constructor(
    private httpClient: HttpClient,
    private router: Router, 
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<RatingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
     this.movie = this.data.movie;
    }

  ngOnInit() {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onCancel() {
    this.dialogRef.close();
  }

  postRating(){
    this.httpClient.post('https://api.themoviedb.org/3/movie/'+this.data.movie+'/rating?api_key=85204a8cc33baf447559fb6d51b18313&guest_session_id='+this.session+'', {'value' : this.selected}).subscribe((res: any) => {
      console.log(res);
  });
  }

  onRateMovie() {
    console.log(this.selected);
    this.postRating();
    this.dialogRef.close();
  }


}

