<div class="card box-white shadow mb-5 px-5 py-5">
  <div class="row">
     <div class="col-12">
        <p class="font-20"><strong>Energy measurements from bills</strong></p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc maximus viverra urna quis interdum. Curabitur in sem
           semper, viverra magna vel, rhoncus mi.
        </p>
     </div>
  </div>
  <div class="row mt-3">
     <div class="col-12">
        <mat-form-field style="width: 350px;">
           <mat-label>See results for</mat-label>
           <mat-select [(ngModel)]="selectedValue">
           <mat-option *ngFor="let result of results" [value]="result.value">
           {{result.viewValue}}
           </mat-option>
           </mat-select>
        </mat-form-field>
     </div>
  </div>
  <div class="row mt-3">
     <div class="col-8">
     </div>
     <div class="col-4">
        <img src="assets/images/energy1.png" class="img-fluid" alt="">
     </div>
  </div>
  <div class="row mt-3">
     <div class="col-12">
        <ng-template #consumptionContent>
           <div class="text-left" style="width: 250px;">
              <div class="row">
                 <div class="col-8">
                    <p class="blue-text">01 Jan '19 to 28 Feb '19</p>
                 </div>
                 <div class="col-4">
                    <p class="blue-text text-right">59 days</p>
                 </div>
              </div>
              <div class="row">
                 <div class="col-12">
                    <p class="">Total<br>
                       <strong>1.87kWh</strong>
                    </p>
                 </div>
              </div>
              <div class="row">
                 <div class="col-6">
                    <p class="">Day<br>
                       <strong>1.71kWh</strong>
                    </p>
                 </div>
                 <div class="col-6">
                    <p class="">Night<br>
                       <strong>0.16kWh</strong>
                    </p>
                 </div>
              </div>
           </div>
        </ng-template>
        <img src="assets/images/energy2.png" class="img-fluid pointer" alt=""  [ngbTooltip]="consumptionContent" alt="" placement="left">
     </div>
  </div>
</div>