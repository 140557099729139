<div class="row">
        <div class="col-lg-8 offset-lg-2">
            <mat-card class="example-card mx-auto">
    
                <div class="row">
                    <div class="col-md-6">
                        <img 
                        mat-card-image src="https://image.tmdb.org/t/p/w500/{{poster}}" 
                        onError="this.src='https://www.mitromaras.gr/novibet-angular/assets/img/image-placeholder.png';" 
                        alt="Photo of {{title}}"
                        class="mb-0">
                    </div>
                    <div class="col-md-6">
                        <mat-card-header>
                            <mat-card-subtitle>release date: <strong>{{release | date}}</strong></mat-card-subtitle>
                        </mat-card-header>
    
                        <mat-card-content>
                           
                            <div class="row">
                                <div class="col-12">
                                    <p>{{overview}}</p>
                                </div>
                            </div>
    
                            <div class="row">
                                <div class="col-md-6">
                                    <p>budget: <mat-card-subtitle><strong>{{budget | number}}</strong></mat-card-subtitle></p>
                                    <p>revenue: <mat-card-subtitle><strong>{{revenue | number}}</strong></mat-card-subtitle></p>
                                </div>
                                <div class="col-md-6">
                                    <p>Vote average: <mat-card-subtitle><strong>{{vote_average}}</strong></mat-card-subtitle></p>
                                    <p>Vote count: <mat-card-subtitle><strong>{{vote_count | number}}</strong></mat-card-subtitle></p>
                                </div>
                                <div class="col-md-12">
                                    <p>Spoken languages: <em *ngFor="let language of languages">{{language.name}}, </em></p>
                                </div>
                                
                                <div class="col-md-12">
                                    <mat-divider></mat-divider>
                                        <mat-card-header class="mt-3">
                                            <mat-card-subtitle>your rating: <strong></strong></mat-card-subtitle>
                                        </mat-card-header>
                                    <mat-divider></mat-divider>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-6">
                                    <p class="text-left"><small><button mat-stroked-button (click)="openRatingDialog()">&#x2606; rate this movie</button></small></p>
                                </div>
                                <div class="col-6">
                                    <p class="text-right"><small><button mat-stroked-button (click)="openCollectionDialog()">add to collection &#x2b;</button></small></p>
                                </div>
                            
                            </div>

                        </mat-card-content>
                    </div>
                </div>
    
            </mat-card>
        </div>
    </div>