<p class="lead">&#x2606; Rate this movie</p>

<mat-form-field>
    <mat-label>select rating</mat-label>
    <mat-select [(value)]="selected">
        <mat-option>No rating</mat-option>
        <mat-option *ngFor="let number of [1,2,3,4,5,6,7,8,9,10]" value="{{number}}">
            <span *ngFor="let item of [].constructor(number);">&#x2606;</span> 
            ({{number}}) 
        </mat-option>
    </mat-select>
</mat-form-field>

<p>You selected: {{selected}}</p>
<div class="row">
  <div class="col-6">
      <p class="text-left">
          <button mat-button (click)="onCancel()">&#x2715; Cancel</button>
      </p>
  </div>
  <div class="col-6">
      <p class="text-right">
          <button mat-button (click)="onRateMovie()">&#x2713; Confirm</button>
      </p>
  </div>
</div>

